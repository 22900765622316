import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
//import * as borsh from '@project-serum/borsh';
import '../src/css/bootstrap.css';
import app from './firebase';
import "../src/css/App.css";
import { Routes, Route, Link } from "react-router-dom";
import './QueenCoinCard.css'; // Importing the CSS file for styling
import { collection, getDocs, setDoc,getFirestore, doc, getDoc, onSnapshot, updateDoc,DocumentData } from "firebase/firestore";
import Reset from './components/Reset';
import PinterestPost from './components/Pinterest'; 
import PinterestPost1 from './components/Pinterest1'; 
import PinterestPost2 from './components/Pinterest2';
import SentimentAnalysis from './components/SentimentAnalysis';
import  { useEffect } from 'react';
import allCNFTs from './allcNFTs.json';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // You can use js-cookie for easier cookie management
import Quiz from './components/Quiz';
import bs58 from 'bs58';
import { getAuth, onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
import Category from "./components/Category"
import Categories from "./components/Categories"
import Post from './components/Post';
import Signup from "./components/SignUp"
import Profile from './components/Profile';
import Quote from './components/Quote';
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,

} from '@solana/wallet-adapter-wallets';
import "./css/tokens.css"
import { clusterApiUrl, Transaction, TransactionInstruction, SystemProgram, Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';
import SentimentAnalysisData from "./components/Sentiment-Analysis-Data"
import { actions, utils, programs, NodeWallet, Connection} from '@metaplex/js'; 
import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from "./components/TermsAndConditions"
import ArticleConceptEditor from './components/ArticleConceptEditor';
import Concepts from './components/Concepts';
import Documentary from './components/Documentary';
import Athlete from './components/Athlete';
import WhitePaper from './components/WhitePaper';
import Leaderboard from './components/Leaderboard';
interface DatacNFT {
  cNFTs: string[];
}
interface DataAPI {
  wallet: string,
  cNFTsCollection: string,
  uid: string,
}
const BN = require("bn.js");

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');
let thelamports = 0;
let theWallet = "9m5kFDqgpf7Ckzbox91RYcADqcmvxW4MmuNvroD5H2r9"
function getWallet(){

    
}
// Define the type for the data you expect to send
interface PostData {
 
    wallet: string;
    cNFTsCollection: string;
  }
/**
* The state of a greeting account managed by the hello world program
*/


  
  /**
   * The expected size of each greeting account.
   */
 

  function extractSlug(urlString: string): string {
    try {
      const url = new URL(urlString);
      const pathname = url.pathname;
      const slug = pathname.startsWith('/') ? pathname.slice(1) : pathname;
      return slug;
    } catch (error) {
      console.error(`Invalid URL: ${urlString}`, error);
      return '';
    }
  }

const App: FC = () => {

  let auth = getAuth(app)
  

/**
 * The public key of the account we are saying hello to
 */
let greetedPubkey: PublicKey;


 /**
* Borsh schema definition for greeting accounts
*/

 /**
  * The expected size of each greeting account.
  */








// Function to perform a POST request

  






    return (
        <Context>
            <Content />
        </Context>
    );
};


export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;



    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );



    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );

  

};

const Content: FC = () => {
    const [signature, setSignature] = useState<string | null>(null);
    const [publicAddress, setPublicAddress] = useState<string | null>(null);
    const wallet = useWallet();
    const [catDocuments, setCatDocuments] = useState<any[]>([]);
    const [officialCNFTs, setOfficialCNFTs] = useState([]);
    const [officialAllCNFTs,setOfficialAllCNFTs] = useState<string[]>([]);
    const db = getFirestore(app);
    const [user, setUser] = useState<User>();
    const [tokens, setTokens] = useState(0);
    const [walletS, setWalletS] = useState("");
    const [validcNFTsI, setValidCNFTsI]= useState(0)
    //let [wallet, setWallet] = useState("");
    let [counterI, setCounterI] = useState(1)
    const [slugAnimal, setSlugAnimal] = useState("")
    let [blockchainCounter, setBlockchainCounter] = useState("")
    let [slug, setSlug] = useState("");
    const [cNFTs, setCNFTs] = useState<string[]>([]);
    const navigate = useNavigate();
    const [uid, setUid] =useState("")
    const [isPinterestPost, setIsPinterestPost] = useState(false)
    const [signedInS, setSignedInS] = useState("SignIn")
    // const { connection } = useConnection();
    const connection = new Connection(clusterApiUrl("devnet"))
    const { publicKey, sendTransaction } = useWallet();
    const [theUserDoc, setTheUserDoc] = useState<DocumentData>()
    const postData = async (url: string, data: PostData): Promise<void> => {
        try {
          const response = await fetch(url, {
            method: 'POST', // Method type
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // Convert the TypeScript object into a JSON string
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          // You can process the response further if needed
          const result = await response.json();
        
          let counterValidI= 0
          const officialAllCNFTs2: DatacNFT = allCNFTs;

          for (var i =0; i<result.length; i++){
            //console.log("cNFTs: "+ result[i].id)
            for (var e=0; e<officialAllCNFTs2.cNFTs.length; e++){
// console.log(officialAllCNFTs[e]+"")
              if(result[i].id==officialAllCNFTs2.cNFTs[e]){
                console.log("valid")
counterValidI+=1;
              }
            }
          }

        
          // set Hold cNFTs data
          const docData = {
            cNFTs: result.length,
        
        };
        await updateDoc(doc(db, "users", uid), docData);
        
 
          setValidCNFTsI(result.length)
          Cookies.remove('validCNFTs');

          Cookies.set('validCNFTs_'+data.cNFTsCollection, result.length+"", { expires: 21 }); // Expires in 21 days

          console.log('Success:', result);
          
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
      // Example usage:
      
 useEffect(() => {
  /* const auth = getAuth();
  signInAnonymously(auth)
  .then(() => {
      // Signed in..
      console.log(auth.currentUser?.uid);
      getSlug()
      getFirestoreDoc()
      getFirestorecNFTsDoc()
       
  })
  .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
  }); */
  const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    setUser(user!)
    setUid(uid)
    checkQuizKeysAndUpdateTokens(uid, setTokens);
    setSignedInS("Profile");
    const walletSub = onSnapshot(doc(db, "wallets", uid), (doc) => {
      
      if (doc.data()?.wallet!= null && doc.data()?.wallet != undefined){
        setWalletS(doc.data()!.wallet)
        console.log("Current data wallet : ", doc.data()!.wallet);

      }
  });
    // ...
  } else {
    // User is signed out
    // ...
  }
});
  checkIfIsPinterestPost()


}, []); // Empty dependency
    
const checkQuizKeysAndUpdateTokens = async (uid: string, setTokens: React.Dispatch<React.SetStateAction<number>>) => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const data = userDoc.data();
    setTheUserDoc(data)
    let totalTokens = 0;

    for (const key in data) {
      if (key.startsWith('quiz_') && typeof data[key] === 'number') {
        totalTokens += data[key];
      }
    }

    if (userDoc.data().cNFTs!= null && userDoc.data().cNFTs!= undefined ){
      setValidCNFTsI(userDoc.data().cNFTs)
    }
    setTokens(totalTokens);
  } else {
    console.error('User document not found');
  }
};

    const signMessage = useCallback(async () => {
        try {
            if (!wallet.connected) {
                alert('Wallet not connected');
                return;
            }
           /*  if (!wallet.signMessage) {
                alert('Please sign message!');
                return;
            } */

          /*   const message = new TextEncoder().encode('Please sign this message to confirm your identity.');
            const signatureArrayBuffer = await wallet.signMessage(message);
            const signatureBase58 = bs58.encode(Buffer.from(signatureArrayBuffer));
            setSignature(signatureBase58);

            const walletPublicKey: PublicKey = wallet.publicKey!;
            setPublicAddress(walletPublicKey.toString());

            console.log(`Signature: ${signatureBase58}`);
            console.log(`Public Address: ${walletPublicKey.toString()}`); */

            const apiUrl = 'https://getcnfts-fupsrotkea-uc.a.run.app';
            let currentpathName = window.location.pathname;
            console.log("pathname: "+ currentpathName)
            // /p/1
            let slugA = "";
         /*    if (currentpathName.startsWith("/animal/")){
               slugA = currentpathName.substring(8, currentpathName.length)
              console.log("slug: "+slugA)
            } */
            const exampleData: DataAPI = {
                wallet:  wallet.publicKey+"",
                cNFTsCollection: "all",
                uid
              };
        
            // Call the function
            //await postData(apiUrl, exampleData);
            

            postData(apiUrl, exampleData).then(() => {
              //navigate('/animal/'+slugA);

            });

        } catch (error) {
            console.error('Signing error:', error);
           // alert('Failed to sign message!');
        }
    },  [wallet]);

    
    const signMessage2 = useCallback(async () => {
      try {
          if (!wallet.connected) {
              alert('Wallet not connected');
              return;
          }
         if (!wallet.signMessage) {
              alert('Please sign message!');
              return;
          } 
          const message = new TextEncoder().encode('Please sign this message to confirm your identity.');
          const signatureArrayBuffer = await wallet.signMessage(message);
          const signatureBase58 = bs58.encode(Buffer.from(signatureArrayBuffer));
          setSignature(signatureBase58);

          const walletPublicKey: PublicKey = wallet.publicKey!;
          setPublicAddress(walletPublicKey.toString());

          console.log(`Signature: ${signatureBase58}`);
          console.log(`Public Address: ${walletPublicKey.toString()}`); 

if (uid == "" || uid == null){
  window.location.replace("/signup/")
}
      

// Add a new document in collection "cities"
await setDoc(doc(db, "wallets", uid), {
  wallet: wallet.publicKey!+""
});


      } catch (error) {
          console.error('Signing error:', error);
         // alert('Failed to sign message!');
      }
  },  [wallet]);

    async function getFirestoreDoc() {
      const querySnapshot = await getDocs(collection(db, "meta_tags"));
      const docsArray: any= [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        docsArray.push({ id: doc.id, ...doc.data() });
      });
      setCatDocuments(docsArray); // Updating state with the fetched documents
    }


    async function getFirestorecNFTsDoc() {

      const docRef = doc(db, "cNFTs", "Official");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        
        console.log("Document data:", docSnap.data());
        setOfficialCNFTs(docSnap.data().collections)
        console.log(docSnap.data().collections)

      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    async function getFirestorecNFTsAllDoc() {

      const docRef = doc(db, "cNFTs", "All");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        
        console.log("Document data:", docSnap.data());
        setOfficialAllCNFTs(docSnap.data().cNFTs)
        console.log(docSnap.data().cNFTs)
        

      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }

    
async function getSlug() {
  let currentpathName = window.location.pathname;
  console.log("pathname: "+ currentpathName)
  // /p/1
  let slug = currentpathName.substring(3, currentpathName.length)
  console.log("slug: "+slug)
setSlug(slug)
}

function checkIfIsPinterestPost(){
  let currentpathName = window.location.pathname;
  if (currentpathName.startsWith("/pinpos/")){
    setIsPinterestPost(true);
  }

}

async function getSlugIfAnimal() {
  let currentpathName = window.location.pathname;
  console.log("pathname: "+ currentpathName)
  // /p/1
  if (currentpathName.startsWith("/animal/")){
    let slug = currentpathName.substring(8, currentpathName.length)
    console.log("slug: "+slug)
  setSlugAnimal(slug)
  }
  return slug

}
    
    return (
       

        <div className="App">
                <div style={isPinterestPost?{display:"none"}:{display:"block"}} className="navbar">
        <div className="navbar-inner ">
          <a id="title" className="brand" href="/"> QuantumCompass </a>
          <ul className="nav">

          </ul>
          <ul className="nav pull-right">
          <li><a href="/categories"><strong > Categories</strong></a></li>

          <li><a href="#">You hold: <strong style={{color:"green"}}>{validcNFTsI}</strong> Valid cNFTs</a></li>

                      <li><a href="https://t.me/+niQALOPsd7diMjQx">Telegram</a></li>
                      <li><a href="https://x.com/quantumcompassx">X</a></li>
                      <li><a  href='/signup/'>{signedInS}</a></li>

                      <li className="divider-vertical"></li>
                      <li><WalletMultiButton /></li>

                    </ul>
        </div>
      </div>
        <br></br>
        <button style={uid!=null&& uid!=undefined && uid!=""?{display:"block"}:{display:"none"}} onClick={signMessage} className='checkNFTs'>Check the NFTs I Hold  </button>
        <br />

        <button style={isPinterestPost?{display:"none"}:{display:"none"}} onClick={signMessage2} className='get_free_cnfts'>Get Free Solana NFTs  </button>
      <div className='solana_tokens'><img id="token_image" src='https://arweave.net/OgBr9-XTZ2mAsqy4-o6xgR1_P06wtyEQLLgEjdqyL0E'></img> <span id='my_tokens'>My Tokens:<strong className='number_of_tokens'>{tokens}</strong>

       </span></div>
      <button style={isPinterestPost?{display:"none"}:{display:"none"}} disabled className='btn'>Mint (coming soon) </button>
<button style={isPinterestPost?{display:"none"}:{display:"none"}} disabled  >Request Airdrop (comming soon)</button>
{wallet.connected?<p className='whiteH'>Total cNFTs: {validcNFTsI}</p>:<div><br></br>
<br></br><br></br>

<p style={isPinterestPost?{display:"none"}:{display:"block"}} className='whiteH'>Please connect a Solana wallet and signup to get FREE NFTs</p></div>}
{walletS==""?<></>: <p>✅Your wallet: <strong> {walletS} is connected!</strong>  </p>}
      
 <p className='info'>Earn points with Quizzes!</p>

<ul className='whiteH'>

<Routes>
<Route path="/" element={<Categories app={app} />} /> 

    <Route path="/category/:slug" element={<Category app={app} user={user} userDoc={theUserDoc} />} /> 
    <Route path="/categories/*" element={<Categories app={app} />} /> 
    <Route path="/signup/" element={<Signup app={app}/>} /> 
    <Route path="/reset-password/" element={<Reset app={app}/>} /> 
    <Route path="/profile/" element={<Profile app={app}/>} /> 
    <Route path="/privacy-policy/" element={<PrivacyPolicy/>} /> 
    <Route path="/terms-and-conditions/" element={<TermsAndConditions />} /> 
<Route path='/pinpos/:slug' element={<PinterestPost app={app} validCNFTs={validcNFTsI} />}></Route>
<Route path='/pinpos1/:slug' element={<PinterestPost1 app={app} validCNFTs={validcNFTsI} />}></Route>
<Route path='/pinpos2/:slug' element={<PinterestPost2 app={app} validCNFTs={validcNFTsI} />}></Route>
<Route path='/quiz/:slug' element={<Quiz firebaseApp={app} />}></Route>
<Route path="/concept/:slug" element={<Concepts firebaseApp={app}></Concepts>}></Route>

<Route path="/quote/:slug" element={<Quote FirebaseApp={app}></Quote>}></Route>
<Route path="/athlete/:slug" element={<Athlete firebaseApp={app}></Athlete>}></Route>
<Route path="/sentiment-analysis/day/:dateday" element={<SentimentAnalysis firebaseApp={app}></SentimentAnalysis>}></Route>

<Route path="/sentiment-analysis/:dateday/article/:id" element={<SentimentAnalysisData firebaseApp={app}></SentimentAnalysisData>}></Route>

<Route path="/documentary/:slug" element={<Documentary firebaseApp={app}></Documentary>}></Route>

<Route path='/leaderboard/' element={<Leaderboard firebaseApp={app}></Leaderboard>}></Route>
<Route path="/animal/:slug"  element={<Post app={app} validCNFTs={validcNFTsI} firebaseuser={user}/>} /> 
<Route path='/articleConceptEditor' element={<ArticleConceptEditor firebaseApp={app}></ArticleConceptEditor>}></Route>
<Route path='/white-paper/' element={<WhitePaper></WhitePaper>}></Route>
    </Routes>

</ul>
<h2 style={isPinterestPost?{display:"none"}:{display:"block"}} className='whiteH'>Tokenomics || <a href='/white-paper/'>WhitePaper</a> || <a href='/leaderboard/'>Leaderboard</a></h2>

        </div>
    );
};
